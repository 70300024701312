import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Textarea } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "textarea"
    }}>{`Textarea`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Textarea from 'mfcl/Textarea'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Textarea accepts user entry data. Textarea uses labels to communicate the textarea field that will accept data when a user submits them.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Textarea} mdxType="Props" />
    <div>
  <span style={{
        "color": "red"
      }}>*</span> Also accepts all
  <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea#Attributes"> textarea attributes</a>
    </div>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={2} __code={'<Textarea className=\"myTestClass\" label=\"Leave Us Feedback\" /> <br />\n<Textarea\n  defaultValue=\"My default value goes here\"\n  label=\"Leave Us Feedback\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea className="myTestClass" label="Leave Us Feedback" mdxType="Textarea" /> <br />
  <Textarea defaultValue="My default value goes here" label="Leave Us Feedback" mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "character-count"
    }}>{`Character Count`}</h2>
    <Playground __position={3} __code={'<Textarea label=\"Leave us Limited Feedback\" characterLimit={150} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea label="Leave us Limited Feedback" characterLimit={150} mdxType="Textarea" />
    </Playground>
    <Playground __position={4} __code={'<Textarea\n  label=\"Leave us Limited Feedback\"\n  characterLimit={150}\n  inputMessage=\"Enter something like \'Hello World\'\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea label="Leave us Limited Feedback" characterLimit={150} inputMessage="Enter something like 'Hello World'" mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "custom-styling"
    }}>{`Custom Styling`}</h2>
    <Playground __position={5} __code={'<Textarea\n  wrapperStyling={{\n    alignItems: \'flex-start\',\n  }}\n  style={{\n    width: \'200px\',\n    resize: \'none\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea wrapperStyling={{
        alignItems: 'flex-start'
      }} style={{
        width: '200px',
        resize: 'none'
      }} mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "field-variations"
    }}>{`Field Variations`}</h2>
    <Playground __position={6} __code={'<Textarea label=\"Start with 10 rows\" rows={10} /> <br />\n<Textarea error /> <br />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea label="Start with 10 rows" rows={10} mdxType="Textarea" /> <br />
  <Textarea error mdxType="Textarea" /> <br />
    </Playground>
    <h2 {...{
      "id": "required-or-disabled"
    }}>{`Required or Disabled`}</h2>
    <Playground __position={7} __code={'<Textarea disabled /> <br />\n<Textarea required />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea disabled mdxType="Textarea" /> <br />
  <Textarea required mdxType="Textarea" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      